// app/PostHogPageView.tsx
'use client'

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { usePostHog } from 'posthog-js/react';
import { createClient } from "@/lib/supabase/client";

export function PostHogPageView() : null {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();
  useEffect(() => {
    // Track pageviews
    const trackUserPageview = async () => {
      const supabase = createClient();
      const { data: { session } } = await supabase.auth.getSession();
      const user = session?.user;
      if (user) {
        posthog.identify(
          user.id,
          {
            name: user.user_metadata.name,
            email: user.email,
            environment: process.env.NEXT_PUBLIC_VERCEL_ENV || 'development',
          }
        );
      }
      if (pathname && posthog) {
        let url = window.origin + pathname
        if (searchParams.toString()) {
          url = url + `?${searchParams.toString()}`
        }
        posthog.capture(
          '$pageview',
          {
            '$current_url': url,
          }
        )
      }
    };
    trackUserPageview();

  }, [pathname, searchParams, posthog])

  return null
}
