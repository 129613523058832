import { SupabaseClient, createClient as createSvcClient } from "@supabase/supabase-js";
import { createBrowserClient } from '@supabase/ssr'

import type { Database } from "@/lib/database.types";

// const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
// const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;
// const supabaseSvcKey = process.env.SUPABASE_SERVICE_ROLE_KEY;
// export const SUPABASE_SVC_CLIENT = createSvcClient<Database>(supabaseUrl!, supabaseSvcKey!);

export function createClient() {
    return createBrowserClient<Database>(
      process.env.NEXT_PUBLIC_SUPABASE_URL!,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
    )
  }
