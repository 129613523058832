'use client';
import posthog from 'posthog-js';

export const initPostHug = () => {
  if (typeof window !== 'undefined')
  {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
        api_host: '/ingest',
        ui_host: 'https://us.posthog.com',
        person_profiles: 'identified_only',
        persistence: 'localStorage+cookie',
        capture_pageview: false, // Disable automatic pageview capture, as we capture manually
        capture_pageleave: true, // Needs to be set manually
    });
  }
  return posthog;
}

export const captureEvent = (eventName: string, properties?: Record<string, any>) => {
  if (typeof window !== 'undefined') {
    posthog.capture(eventName, properties);
  }
}

export const capturePageView = (path: string) => {
  if (typeof window !== 'undefined') {
    posthog.capture('$pageview', {
      path,
    });
  }
}
