// app/providers.tsx
'use client'
import { initPostHug } from '@/lib/posthog/client'
import { PostHogProvider as PHProvider } from 'posthog-js/react'

export function PostHogProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return <PHProvider client={initPostHug()}>{children}</PHProvider>
}
